<template>
  <div>
    <div id="code" ref="cap" />
  </div>
</template>
<script>
window.NVC_Opt = {
  appkey: 'FFFF0N0000000000A0C0',
  scene: 'ic_login',
  trans: { 'key1': 'code0', 'nvcCode': 200 },
  elements: [
    '//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png',
    '//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png'
  ],
  bg_back_prepared: '//img.alicdn.com/tps/TB1skE5SFXXXXb3XXXXXXXXXXXX-100-80.png',
  bg_front: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABQCAMAAADY1yDdAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURefk5w+ruswAAAAfSURBVFjD7cExAQAAAMKg9U9tCU+gAAAAAAAAAIC3AR+QAAFPlUGoAAAAAElFTkSuQmCC',
  obj_ok: '//img.alicdn.com/tfs/TB1rmyTltfJ8KJjy0FeXXXKEXXa-50-74.png',
  bg_back_pass: '//img.alicdn.com/tfs/TB1KDxCSVXXXXasXFXXXXXXXXXX-100-80.png',
  obj_error: '//img.alicdn.com/tfs/TB1q9yTltfJ8KJjy0FeXXXKEXXa-50-74.png',
  bg_back_fail: '//img.alicdn.com/tfs/TB1w2oOSFXXXXb4XpXXXXXXXXXX-100-80.png',
  upLang: {
    'cn': {
      _ggk_guide: '请摁住鼠标左键，刮出两面盾牌',
      _ggk_success: '恭喜您成功刮出盾牌<br/>继续下一步操作吧',
      _ggk_loading: '加载中',
      _ggk_fail: ['呀，盾牌不见了<br/>请', 'javascript:noCaptcha.reset()', '再来一次', '或', 'https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN', '反馈问题'],
      _ggk_action_timeout: ['我等得太久啦<br/>请', 'javascript:noCaptcha.reset()', '再来一次', '或', 'https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN', '反馈问题'],
      _ggk_net_err: ['网络实在不给力<br/>请', 'javascript:noCaptcha.reset()', '再来一次', '或', 'https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN', '反馈问题'],
      _ggk_too_fast: ['您刮得太快啦<br/>请', 'javascript:noCaptcha.reset()', '再来一次', '或', 'https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN', '反馈问题']
    }
  }
}
require('./index.js')
require('./guide.js')
export default {
  name: 'SmartCaptcha',
  props: {
    scene: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: Number,
      default: 44
    },
    defaultTxt: {
      type: String,
      default: '点击按钮开始智能验证'
    },
    successTxt: {
      type: String,
      default: '验证成功'
    },
    failTxt: {
      type: String,
      default: '请在此点击按钮刷新'
    },
    scaningTxt: {
      type: String,
      default: '智能检测中'
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      /* eslint-disable */
      this.icCap = new smartCaptcha({
        renderTo: '#code',
        width: this.width,
        height: this.height,
        default_txt: this.defaultTxt,
        success_txt: this.successTxt,
        fail_txt: this.failTxt,
        scaning_txt: this.scaningTxt,
        success: (data) => {
          const valiteCodeData = {
            sessionId: data.sessionId,
            scene: this.scene,
            sig: data.sig,
            token: NVC_Opt.token
          }
          this.$emit('success', { data: valiteCodeData, control: this.icCap })
        }
      })
      this.icCap.init()
    }
  }
}
</script>
<style lang="scss">
.nc_scale {
  background: #e8e8e8 !important;
}
.nc_scale div.bc_bg {
  background: #7ac23c !important;
}
.nc_scale .scale_text2 {
  color: #fff !important;
}
.nc_scale span {
  border: 1px solid #ccc !important;
}
.errloading {
  border: #faf1d5 1px solid !important;
  color: #ef9f06 !important;
}
</style>
